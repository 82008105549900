<template>
  <div>
    <div
      class="home"
      style="position: relative;height: calc(100vh - 150px);min-width:1024px;overflow:auto;"
    >
      <div class="login_btn">
        <img v-if="styleBackImg" :src="styleBackImg" alt />
        <img v-else src="../assets/logo_btn.png" alt />
      </div>

      <!-- <div class="logo_buttom">
      <img src="../assets/logo_buttom.png" alt />
      </div>-->
      <div
        style="
        width: 400px;
        background: #fff;
        padding: 42px;
        top: 160px;
        right: 440px;
        position: absolute;
      "
      >
        <div style="margin-bottom:10px;font-weight:700;color:#333;text-align:center">{{name}}</div>
        <div style="margin: 20px 0; font-size: 18px">找回密码</div>

        <el-form :model="userForm" status-icon :rules="userRules" ref="userForm">
          <el-form-item prop="username">
            <el-input
              type="text"
              placeholder="请输入用户名"
              v-model="userForm.username"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="smsCode">
            <el-input placeholder="请输入验证码" v-model="userForm.smsCode" clearable>
              <template slot="append">
                <div style="cursor: pointer" @click="getSmsCode()" v-show="!isShowSconds">获取验证码</div>
                <div v-show="isShowSconds" class="font-color-brand">{{ seconds }}S后重新获取</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" placeholder="请输入密码" v-model="userForm.password" clearable></el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <el-input type="password" placeholder="请再次输入密码" v-model="userForm.checkPass" clearable></el-input>
          </el-form-item>
          <div>
            <el-button style="width: 100%" type="primary" @click="submitForm('userForm')">重置</el-button>
          </div>
        </el-form>

        <div style="text-align: right">
          <el-button type="text" v-prev path="/login">返回登录</el-button>
        </div>
      </div>
    </div>
    <Footer :styleAddress="styleAddress" :stylePhone="stylePhone"></Footer>
  </div>
</template>

<script>
import Top from '../components/top.vue'
import Footer from '../components/footer.vue'
import { api } from '../api/base';
export const customerSetting = api()('customer.setting.json');
export const userSendFindPasswordCode = api()(
  'user.send.find.password.code.json'
);
export const userFindPassword = api()('user.find.password.json');
export default {
  components: {
    [Top.name]: Top,
    [Footer.name]: Footer
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };
    var checkSmsCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'));
      }
      setTimeout(() => {
        if (value.length !== 6) {
          callback(new Error('请输入6位验证码'));
        } else {
          callback();
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      const reg = /^[\w_-]{6,16}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码必须为6-16位数字或者字母组成'));
      } else {
        if (this.userForm.checkPass !== '') {
          this.$refs.userForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userForm: {
        username: '',
        smsCode: '',
        password: '',
        checkPass: ''
      },
      userRules: {
        username: [{ validator: validatePhone, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'change' }],
        checkPass: [{ validator: validatePass2, trigger: 'change' }],
        smsCode: [{ validator: checkSmsCode, trigger: 'blur' }]
      },

      activeName: 'first',
      stylePhone: '',
      styleAddress: '',
      name: '',
      styleBackImg: '',
      styleLogo: '',
      isShowSconds: false,
      seconds: 60,
      config: {}
    };
  },
  async mounted () {

    // this.$route.params.$preload.customSetting.forEach(it => {
    //   this.config[it.name] = it.value;
    // });
    const params = {
      customerCode: 'kw5r1ij50'
    };
    const res = await customerSetting(params);
    this.stylePhone = res.stylePhone
    this.styleAddress = res.styleAddress
    this.styleBackImg = res.styleBackImg
    this.name = res.name
    this.styleLogo = res.styleLogo
    console.log(res)
  },
  methods: {

    async getSmsCode () {
      if (!this.userForm.username) {
        return this.$message.error('请输入用户名');
      }
      await userSendFindPasswordCode({
        phone: this.userForm.username
      });
      this.$message.success('验证码发送成功');
      this.isShowSconds = true;
      const _interval = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          clearInterval(_interval);
          this.isShowSconds = false;
          this.seconds = 59;
        }
      }, 1000);
    },

    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await userFindPassword({
            phone: this.userForm.username,
            smsCode: this.userForm.smsCode,
            password: this.userForm.password
          }).then(() => {
            this.$message.success('密码修改成功');
            this.$router.push({
              path: '/login'
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style>
.el-divider.line {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
.el-tabs__nav {
  display: flex;
  width: 100%;
}

.el-tabs__item {
  flex-grow: 1;
}

.el-tabs__nav > .el-tabs__item:last-child {
  border-right: none;
}
.el-tabs--border-card {
  border: 1px solid #dcdfe6;
}
.top-left {
  width: 136px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
.top-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #131824;
  width: 50%;
}
.login_btn {
  width: 100%;
  height: calc(100vh - 150px);
  img {
    width: 100%;
    height: 100%;
  }
}
.logo_buttom {
  width: 100%;
  display: flex;
  align-items: unset;
  justify-content: center;
  margin: 100px 0 20px 0;
  img {
    width: 1300px;
  }
}
</style>
